@import "src/utils/index";

.wrapper{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
}

.wrapperBg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.header{
  width: 100%;
  padding: 32px 125px;
  display: flex;
  justify-content: flex-end;
}

.content{
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 100;
}

