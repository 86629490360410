@import "src/utils/index";

.consultation {
  padding: 20px;
  max-width: 1024px;

  &__header{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title{
    @include text($f-size-36, $black);
    padding: 0 10px;
  }

  &__row{
    width: 100%;
    display: flex;
    margin-left: -5px;
    margin-right: -5px;
  }

  &__colM{
    width: 50%;
    padding: 0 5px;
  }

  &__colS{
    width: 33.3%;
    padding: 0 5px;
  }

  &__box{
    padding: 5px 0;
  }

  &__label{
    padding-bottom: 5px;
    @include text($f-size-16, $black);
    display: inline-block;
  }

  &__createBtn {
    height: 30px;
  }
}
