@import "src/utils/index";

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  padding: 65px 0 0;
}

.container{
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-top: 65px;

  @media screen and(min-width: 560px){
    flex-direction: row;
  }
}

.col{
  width: 100%;
  @media screen and(min-width: 560px){
    width: 50%;
  }

  &:last-of-type{
    padding: 0 20px;
  }
}

.bgImage{
  background-repeat: no-repeat;
  background-size: cover;
  width: 540px;
  min-height: 600px;
  height: 1010px;
}

.box{
 // @include container();
  display: block;
  // margin: 30px 0 0;
}

.boxHide{
  display: none;
}

.text{
  @include text($f-size-16, $black);
}

.list{
  padding-left: 12px;
  margin: 0;

  &__li{
    padding-left: 20px;
  }
}

.link{
  @include text($f-size-18, $dark);
  letter-spacing: 1px;
  display: block;
  width: fit-content;
  position: relative;
  text-align: center;
  margin: 65px auto 0;
  text-decoration: none;

  &_line{
    display: inline-block;
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
    background-color: $dark;
  }
}
