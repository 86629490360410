@import "src/utils/index";

.lessonPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 65px 0;

  &__title{
    @include text($f-size-36, $dark);
    text-align: center;
  }

  &__box{
    @include container();
    display: block;
   // margin: 30px 0 0;
  }

  &__boxHide{
    display: none;
  }

  &__videoWrapper{
    max-width: 768px;
    width: 100%;
    margin-top: 30px;
  }
}

.video{
  @include video();
}


.banner{
  max-width: 768px;
  width: 100%;
  margin-top: 30px;
}