@import "src/utils/index";

.footerClient {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 27px 10px;

  @include media--min($width--phone-l) {
    flex-direction: row;
  }

  &__social{
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    @include media--min($width--phone-l){
      margin-right: 30px;
      margin-bottom: 0;
    }


    & p {
      margin-right: 10px;
    }
  }

  &__icon{
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  &__text{
    @include text($f-size-16, $main-text);
    margin: 0;
  }
}

