@import "src/utils/index";

.course {
  padding: 20px;
  max-width: 1024px;

  &__header{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title{
    @include text($f-size-36, $black);
    padding: 0 10px;
  }

  &__row{
    width: 100%;
    display: flex;
    margin-left: -5px;
    margin-right: -5px;
  }

  &__colM{
    width: 50%;
    padding: 0 5px;
  }

  &__colS{
    width: 33.3%;
    padding: 0 5px;
  }

  &__box{
    padding: 5px 0;
  }

  &__label{
    padding-bottom: 5px;
    @include text($f-size-16, $black);
    display: inline-block;
  }

  &__createBtn {
    height: 30px;
  }
}


.list{
  list-style: none;
  margin: 0;
  padding: 0;
  &__item{
    padding: 5px 10px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #c2c2c2;
  }

  &__text{
    font-weight: 500;
    font-size: 16px;
    flex-grow: 1;
    margin: 0;
    max-width: 260px;
  }

  &__controls{

  }
}
