@import "src/utils/index";

.dayOfTheWeek {
  width: 100%;
  @include singleContainer();
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;

  &::-webkit-scrollbar {
    width: 0;
  }

  &__image {
    //object-fit: contain;
    width: fit-content;
  }

  @media screen and (min-width: 1921px) {
    height: 100vh;
  }
}

.dayOfTheWeekInner {
  overflow: scroll;
}