@import "src/utils/index";

.noAccessText{
  max-width: 520px;
  margin: auto;
  @include text($f-size-20,$black);
}

.temporaryCourse {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 65px 0 65px 20px;

  &__title{
    @include text($f-size-36, $light);
    text-align: center;
  }

  &__box{
    display: block;
    margin-top: 30px;

    &Hide{
      display: none;
    }
  }

  &__content{
    padding: 30px 0;
  }

  &__button{
    background: rgba(4, 11, 51, 0.83);
    color: #ffbd28;
    border: 0;
    padding: 10px 20px;
    font-size: 18px;
    margin: 20px auto;
    display: block;
    cursor: pointer;
  }
  
  &__info{
    text-align: center;
    margin: 0 auto 10px;
    font-size: 18px;
  }
}

.accessDate{
  position: absolute;
  top: 10px;
  right: 10px;
  @include text($f-size-14, $light);

  &__date{
    display: inline-block;
    margin-left: 10px;
  }
}

.list{

  &__title{
    @include text($f-size-20, $light);
    text-decoration: underline;
  }

  &__wrapper{
    padding: 10px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

  }
}

.lesson{
  width: calc(33.33% - 20px);
  cursor: pointer;

  @media screen and (max-width: 730px) {
    width: calc(50% - 20px);
  }

  @media screen and (max-width: 500px) {
    width: calc(100% - 20px);
  }

  &__title{
    @include text($f-size-14, $light);
  }

  &__videoWrapper{
    width: 100%;
    margin-bottom: 20px;
  }

  & iframe {
    width: 100%;
  }
}

.banner{
  margin-bottom: 10px;
  & img {
    max-width: 100%;
  }
}