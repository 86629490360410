.wrapper{
  display: flex;
  height: 100vh;
}

.sidebar{
  max-width: 200px;
  width: 100%;
  height: 100%;
  border-right: 1px solid #e3e3e3;
  display: flex;
  flex-direction: column;

  &__close{
    max-width: 50px;
  }
}

.content{
  flex-grow: 1;
  height: 100%;
}

.contentInner{
  padding: 10px;
  height: calc(100vh - 38px);
  overflow-x: auto;
}

.toolbar{
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  background-color: #e3e3e3;
}

.sidebarToggle{
  background-color: #e3e3e3;
  padding: 10px;
}
