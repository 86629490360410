@import "src/utils/index";

.stagesPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 97%;
  padding: 65px 0;

  &__button{
    background: rgba(4, 11, 51, 0.83);
    color: #ffbd28;
    border: 0;
    padding: 10px 20px;
    font-size: 18px;
    margin: 20px auto;
    display: block;
    cursor: pointer;
  }
  
  &__info{
    text-align: center;
    margin: 0 auto 10px;
    font-size: 18px;
  }

  &__info_price{
    text-align: center;
    margin: 20px auto 10px;
    font-size: 18px;
  }
}

.row{
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  @include media--min($width--tablet){
    margin-left: -6px;
    margin-right: -6px;
  }
}

.list {
  display: flex;
  flex-wrap: wrap;

  @include container();
  margin-top: 30px;


  &__item {
    width: 100%;
    text-align: center;
    position: relative;
    overflow: hidden;
    z-index: 1;
    padding: 6px;

    @include media--min($width--phone-sm) {
      width: 50%;
    }

    @include media--min($width--phone-l){
      width: 25%;
    }
  }

  &__link{
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 6px;
    right: 6px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    text-decoration: none;
    padding: 10px 0;

    & span {
      opacity: 0;
      @include text($f-size-14, $dark);
      transition: .3s;
      margin: 0 5px;
      display: inline-block;
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.54);
      cursor: pointer;
      span {
        opacity: 1;
        @include text($f-size-14, $dark);
        transition: .3s;

      }
    }
  }
}

.video{
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0;
  height: 0;
  overflow: hidden;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}


.noAccessText{
  max-width: 520px;
  margin: auto;
  @include text($f-size-20,$black);
}
