$media-s: 360px;
$media-sm: 520px;
$media-md: 768px;
$media-lg: 1024px;
$media-xl: 1440px;

$f-size-11: 11px;
$f-size-14: 14px;
$f-size-15: 15px;
$f-size-16: 16px;
$f-size-18: 18px;
$f-size-20: 20px;
$f-size-36: 36px;
$f-size-40: 40px;
$f-size-42: 42px;

$black: #000000;
$white: #ffffff;
$grey: #606060;
$main-text: #ffbd28;
$dark: #040b33;
$light: #0f174e;
$light-s: #a7bbe7;

$f-main: Onest sans-serif;
$f-second: DS Izmir sans-serif;
