.navPanel {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &__item{
    height: 40px;
    text-decoration: none;
    padding: 0 10px;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    outline: none;

    &:hover{
      background-color: #e3e3e3;
      cursor: pointer;
    }

    &Logout{
      margin-top: auto;
    }
  }

  &__itemText{
    color: #000000;
    font-size: 16px;
    display: flex;
    align-items: center;

    & svg {
      margin-right: 10px;
    }
  }
}

