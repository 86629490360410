@import "src/utils/index";

.coursePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 97%;
  padding: 65px 0;

  &__title{
    @include text($f-size-36, $dark);
    text-align: center;
    margin-top: 0;
  }
  &__videoWrapper {
    max-width:768px;
    width: 100%;
    margin-top: 30px;
  }
}

.video{
  @include video();
}

.stagesList{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-left: 0;
  margin-top: 30px;
  list-style: none;
  width: 80%;

  @include media--min($width--tablet) {
    flex-direction: row;
    align-items: center;
  }

  &__item{
    position: relative;
    display: inline-block;
    @include text($f-size-20, $dark);
    text-decoration: none;
    padding: 10px 20px;
    line-height: 22px;

    @include media--min($width--phone-l){
      width: 25%;
      text-align: center;
    }

    &:hover{
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__itemTooltip{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    @include text($f-size-14, $dark);
    display: inline-block;
    padding: 10px;
    background-color: #e7e7e7;
  }
}

.bullingLink {
  text-align: center;
  box-sizing: border-box;

  &__link{
    @include text($f-size-20, $dark);
  }
}
