@import "src/utils/index";
.allCourses {
  min-height: 97%;
  padding: 65px 0;
}

.noAccessText{
  max-width: 520px;
  margin: auto;
  @include text($f-size-20,$black);
}

.banner{
  width: 100%;
  height: auto;
  position: relative;
  cursor: pointer;
  margin: 0 auto;
  background-color: $light-s;
 // padding: 20px;
  display: block;
  text-decoration: none;

  @include media--min($width--tablet) {
    width: 770px;
    height: 141px;
  }

  &__cover{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__title{
    @include text($f-size-20, $light);
    margin: 0;
  }

  &__text{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    @include text($f-size-16, $light);

    &Hide{
      display: none;
    }
  }
}

.list{
  margin-top: 90px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0;
  list-style: none;

  @include media--max($width--tablet) {
    margin-top: 0;
  }

  &:last-of-type{
    margin-top: 0;
  }


  &__item{
    width: 33.3%;
    padding: 0 10px 45px;
    text-align: center;

    @include media--max($width--phone-l) {
      width: 100%;
    }
  }

  &__link{
    @include text($f-size-16, $light);
    margin: 0;
    background-color: transparent;
    border: none;
    outline: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover{
      cursor: pointer;
    }

    &Span{
      display: block;
      @include text($f-size-20, $dark);
      text-decoration: none;
      padding: 10px 20px;
      line-height: 22px;

      @include media--min($width--phone-l){
        //width: 25%;
        text-align: center;
      }
    }
  }

}
