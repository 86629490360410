@import "src/utils/index";

.pagination{
  @include container();
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  &__btn{
    @include text($f-size-16, $black);
    background-color: transparent;
    border: none;
    outline: none;

    &:hover{
      cursor: pointer;
    }
  }
}

