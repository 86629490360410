.breadCrumbs {
  display: flex;
  align-items: center;
  padding: 17px 16px;
  position: absolute;
  right: 20px;
  //bottom: 0;
  z-index: 100;

  @media (max-width: 767px) {
    display: none;
  }

  & button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }

  &__icon{
    width: 25px;
  }
}

//special behaviour for content/day-of-the-week page:
.breadCrumbs-dotw {
  display: flex;
  align-items: center;
  padding: 17px 16px;
  position: absolute;
  right: 20px;
  bottom: 78px;
  z-index: 100;

  @media (max-width: 767px) {
    display: none;
  }

  & button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }

  &__icon{
    width: 25px;
  }
}