.appLoader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.02);

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;

  &__text {
    font-size: 24px;
    font-weight: 900;
    color: white;
  }
}

