@import "src/utils/index";

.postPage {
  padding: 65px 10px;
  height: 97%;
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  &__item {
    width: 25%;
    padding: 1%;

    @include media--max($width--tablet){
      width: 100%;
    }
  }
}

.card {
  width: 100%;

  &__img {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56%;
    background-color: #a7bbe7;

    & img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__body {
    padding: 10px;
    background-color: #f6f5f5;

    &NoBg{
      background-color: transparent;
    }
  }

  &__title {
    @include text($f-size-16, $black);
    margin: 0;

    &Cat{
      text-align: center;
      text-decoration: underline;
      color: #0f174e;
      &:hover{
        cursor: pointer;
      }
    }
  }

  &__link {
    @include text($f-size-14, $light);
    margin-top: 65px;
    display: block;
    text-align: right;

    &:hover{cursor: pointer}
  }
}

.singlePost {
  padding: 65px 10px;
  max-width: 768px;
  margin: 0 auto;
  position: relative;

  &__image {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56%;
    background-color: #f6f5f5;
    margin-bottom: 30px;


    & img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

    }
  }

  &__box{
    display: block;
    margin: 30px 0;

    &Mbottom{
      margin-top: 0;
    }

    &Hide{
      display: none;
    }
  }
}
