@import "src/utils/index";

.users {
  &__title{
    @include text($f-size-36, $black);
    padding: 0 10px;
  }

  &__subtitle{
    @include text($f-size-18, $grey)
  }
}

.row{
  display: flex;
}

.box{
  max-width: 500px;
  width: 100%;
  padding: 5px 10px;

  &__column{
    display: flex;
    flex-direction: column;
  }

  &__inner{
    padding: 20px 0;
  }
}

.buttonWrapper{
  max-width: 300px;
  margin: 10px 0 0 auto;
}

.button{
  font-size: 12px;
}
