@import "src/utils/index";

.errorPage {
  display: flex;
  align-items: center;
  justify-content: center;
 height: 100%;

  &__title{
    @include text(120px, red);
    margin: 0;
  }

  &__text{
    @include text($f-size-16, $black);
    margin: 0;
  }
}

.inner{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}
