@import "src/utils/index";

.switcher{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 30px;
  z-index: 100;

  &__button{
    border: none;
    outline: none;
    background-color: transparent;

    @include text($f-size-16, $main-text);

    &:hover{
      color: $light;
      cursor: pointer;
    }

    &Single{
      @include text($f-size-16, $light);

      &:hover{
        color: $main-text;
        cursor: pointer;
      }
    }

    &Auth{
      @include text($f-size-16, $white);

      &:hover{
        color: $main-text;
        cursor: pointer;
      }
    }
  }
}
