@import "src/utils/index";

.input{
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 0;

  &__field{
    padding: 5px;
    @include text($f-size-16, $black);

    &::placeholder{
      @include text($f-size-16, $black)
    }
  }

  &__label{
    font-size: 16px;
    padding-bottom: 5px;
    font-family: Averta CY sans-serif;
  }
}

.buttonFilled {
  background-color: $dark;
  color: white;
  font-family: Averta CY sans-serif;
  font-size: 16px;
  font-weight: 500;
  border: none;
  outline: none;
  padding: 10px 58px;
  border-radius: 10px;

  &:hover{
    cursor: pointer;
    background-color: #797979;
  }

  &:disabled{
    opacity: .8;

    &:hover{
      cursor: initial;
      background-color: #606060;
    }
  }
}

.buttonOutline {
  background-color: white;
  color: black;
  font-family: Averta CY sans-serif;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #606060;
  outline: none;
  padding: 10px 20px;

  &:hover{
    cursor: pointer;
    background-color: #e3e3e3;
  }

  &:disabled{
    opacity: .5;

    &:hover{
      cursor: initial;
      background-color: white;
    }
  }
}

.checkbox{
  display: flex;
  align-items: center;

  &__box{
    width: 20px;
    height: 20px;
  }

  &__label{
    @include text($f-size-16, $black);
    margin-left: 10px;
  }
}
