@import "src/utils/index";

.wrapper{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.displayName{
  @include text($f-size-18, $dark);
  position: static;
  padding: 20px;
  text-align: right;
  margin: 0;

  @include media--min($width--phone-l) {
    position: absolute;
    top: 60px;
    right:  20px;
    margin: 0;
  }
}

.header{
  padding: 32px 40px;
  text-align: center;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  position: relative;

  &__single{
    padding: 10px 10px;
    text-align: center;
    position: relative;

    @include singleContainer();

    & .header__line{
      width: 40%;

      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }

  &__top{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    right: 0;

    @include media--max($width--laptop) {
      position: static;
      margin-bottom: 16px;
    }
  }

  &__topRight{
    right: -10%;
  }

  &__authBox{
    display: flex;
    align-items: center;
  }

  &__link{
    background-color: transparent;
    border: none;
    outline: none;

    @include text($f-size-16, $light-s);
    text-decoration: none;
    margin-right: 10px;

    &:hover{
      color: $light;
      cursor: pointer;
    }
  }

  &__title{
  margin: 0;
    text-align: center;
    text-shadow: 1px 0 1px #000000;

    @include text($f-size-20, $main-text);

    @include media--min($width--tablet){
      margin: 0;
      @include text($f-size-36, $main-text);
    }

    @include media--min($width--phone-l) {
      @include text($f-size-40, $main-text);
    }

    & a {
      color: inherit;
      text-decoration: none;
    }

    &Single{
      @include text($f-size-16, $main-text);

      @include media--min($width--tablet){
        margin: 0;
        @include text($f-size-20, $main-text);
      }
    }
  }

  &__subtitle{
    margin: 10px 0 0;
    text-align: center;
    text-shadow: 1px 0 1px #000000;

    @include textSecond($f-size-20, $main-text);

    @include media--min($width--phone-l) {
      @include textSecond($f-size-36, $main-text);
    }
  }

  &__line{
    width: 90%;
    height: 3px;
    box-shadow: 1px 1px 0 0.2px rgba(65, 65, 65, 0.81);
    display: block;
    margin: 0 auto;
    background-color: $main-text;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: calc(100vh - 320px);
  overflow: auto;
}

.contentReverse {
  display: flex;
  flex-direction: column-reverse;
  flex-grow: 1;
}

.inner{
  flex-grow: 1;
 // height: 100%;
  //position: relative;
}

.innerSinglePage{
  flex-grow: 1;
  padding-bottom: 30px;
  position: relative;
  height: 100%;
  overflow-x: auto;

  @include singleContainer()
}
