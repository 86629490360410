@import "src/utils/index";

.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;

  &__title{
    @include text($f-size-42, $main-text);
  }

  &__link{
    @include text($f-size-16, $black);
    text-align: center;
    text-decoration: none;
    margin-top: 10px;
    display: block;
  }
}


.form{
  max-width: $media-s;
  width: 100%;
  background-color: $white;
  padding: 20px;
  position: relative;

  &__box{
    padding: 6px 0;

    //&:last-of-type{
      text-align: center;
      margin-top: 30px;
    //}
  }

  &__title{
    @include text($f-size-20, $dark);

    text-align: center;

    & span{
      text-transform: uppercase;
    }
  }

  &__text{
    width: 80%;
    margin: 50px auto 30px;

    @include text($f-size-16, $black);
    text-align: center;
  }
}

.breadCrumbs{
  display: flex;
  align-items: center;
  padding: 17px 16px;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 100;

  & button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }

  &__icon{
    width: 25px;
  }
}
