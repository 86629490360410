@import "src/utils/index";

.switcher{
  width: 100%;
  text-align: right;
  display: block;
  padding: 20px;
  cursor: pointer;

  @media (max-width: 676px) {
    position: absolute;
    z-index: 1;
  }

  @media (min-width: 1000px) {
    display: block;
  }
  @media (min-width: 1001px) {
    display: none;
  }
}

.switcherClose{
  text-align: right;
  display: inline-block;
  padding: 20px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;

  @media (min-width: 1000px) {
    display: block;
  }
  @media (min-width: 1001px) {
    display: none;
  }
}

.navClient {
 // background-color: $dark;
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transform: translateX(0%);
  transition: .3s;
  z-index: 999;
  
  @media (min-width: 1000px) {
    display: none;
  }
  @media (min-width: 1001px) {
    display: block;
  }

  @include media--min($width--tablet) {
    position: sticky;
  }

  &__bg{
    @include singleContainer();
    background-color: $white;
  }

  &__inner{
    @include singleContainer();
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 100%;

    @include media--min($width--laptop-wrap){
      flex-direction: row;
      align-items: center;
    }
  }

  &__link{
    @include text($f-size-16, $main-text);
    text-align: center;
    text-decoration: none;
    padding: 22px 20px;
    position: relative;
    display: flex;
    align-items: center;

    @include media--min($width--laptop-wrap){
      width: 16.66%;
      justify-content: center;

      &:after{
        content: '';
        width: 2px;
        height: 45px;
        background-color: $main-text;
        display: inline-block;
        margin-left: auto;
        position: absolute;
        right: 0;
      }


      &:last-of-type:after{
        content: none;
      }
    }

    @include media--min($width--laptop){
      @include text($f-size-15, $main-text);
    }

    &:hover{
      color: #fffbf3;

    }
  }
}

.top{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transform: translateX(-100%);
  transition: .3s;

  @include media--min($width--tablet){
    transform: translateX(0%);
    position: sticky;
  }
}
