@import "variable";
@import "breakpoints";

@mixin text(
  $size : $f-size-14,
  $color: $black,
) {
  font-family: $f-main;
  font-size: $size;
  font-style: normal;
  color: $color;
  user-select: none;
}

@mixin textSecond(
  $size : $f-size-14,
  $color: $black,
) {
  font-family: $f-second;
  font-size: $size;
  font-style: normal;
  color: $color;
  user-select: none;
}

@mixin container() {
  max-width: $width--phone-l;
  width: 100%;
  margin: 0 auto;
}

@mixin singleContainer(){
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  background-color: $white;

  @media screen and (min-width: 1921px) {
    max-width: 85%;
  }
}

@mixin video() {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0;
  height: 0;
  overflow: hidden;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
