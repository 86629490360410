@import "src/utils/index";

.tooltip{
  position: relative;
  display: block;
  @include text($f-size-20, $dark);
  text-decoration: none;
  padding: 10px 20px;
  line-height: 22px;

  @include media--min($width--phone-l){
    //width: 25%;
    text-align: center;
  }

  &:hover{
    text-decoration: underline;
    cursor: pointer;
  }

  &__item{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    @include text($f-size-14, $dark);
    display: inline-block;
    padding: 10px;
    background-color: #e7e7e7;
  }
}
