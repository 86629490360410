@import "src/utils/index";

.reader{
  width: 100%;
  display: flex;

  &__box{
    &:first-of-type{
      flex-grow: 1;
    }
  }

  &__preview{
    width: 250px;
    height: calc(250 / 16 * 9px);
    overflow: hidden;

    & img {
      width: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  &__text{
    @include text($f-size-16, $black);
    margin: 5px 0 0 10px;
  }
}

