$width--desktop: 1920px;
$width--laptop-X: 1366px;
$width--laptop: 1256px;
$width--laptop-wrap: 1000px;
$width--tablet: 999px;
$width--phone-l: 768px;
$width--phone-sm: 425px;

@mixin media--max($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}

@mixin media--min($width) {
  @media screen and (min-width: $width) {
    @content;
  }
}

