@import "src/utils/index";

.titleWRap {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;

  @include media--min($width--phone-l) {
    flex-direction: row;
  }

  &__title {
    @include text($f-size-20, $dark);
    text-align: center;
    margin-top: 0;

    @include media--min($width--phone-sm){
      @include text($f-size-36, $dark);
    }

    @include media--min($width--phone-l){
      margin: 0;
    }
  }
}
