@import "src/utils/index";

.page{
  padding: 65px 30px;
}

.subtitle{
  @include text($f-size-16, $black);
  font-weight: bold;
}
.text{
  @include text($f-size-14, $black);
}
